<template>
  <div class="view-frame">
    <div class="color-bar" :style="{backgroundColor: getCustomerColor}"></div>
    <div class="spacing-frame">
      <div class="logo-frame">
        <img :src="computedImageSrc" @error="computedImageSrc = null"/>
      </div>
      <div class="main-content welcome-view">
        <div class="inner" id="inner" style="width: 100%; padding-top: 30px;">
          <span class="head" id="head">{{ getCurrentViewContent.view_name }}</span>
          <span v-if="getCurrentViewContent.period_start" class="sub" id="sub">{{ getCurrentViewContent.period_start }} - {{ getCurrentViewContent.period_end }} Uhr</span>
        </div>
      </div>
      <div class="room-number">
        <div class="inner">
          {{ getCurrentViewContent.room_number }}
        </div>
      </div>
      <div class="sub-content">
        <div class="inner">
          {{ getCurrentViewContent.persons }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {adjustFontSize} from "@/utils/helpers"

export default {
  name: "Welcome",
  mounted () {
      this.$nextTick(() => {
        this.adjustFontSize('inner', 'head', this.defaultFontHeight, this.minFontHeight, 'sub')
      })
  },
  watch: {
      getCurrentViewContent: {
          handler() {
            this.$nextTick(() => {
                this.adjustFontSize('inner', 'head', this.defaultFontHeight, this.minFontHeight, 'sub')
            })
          },
          deep: true
      }
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCustomerId: 'getCustomerId',
      getResourcePath: 'getResourcePath',
      getCustomerColor: 'getCustomerColor',
      getLogoUrl: 'getLogoUrl'
    }),
    computedImageSrc () {
      return this.getLogoUrl || this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
    }
  },
  data() {
    return {
      header: 'Herzlich Willkommen',
      imageSrc: null,
      minFontHeight: 2,
      defaultFontHeight: 2.9,
      fontSize: 2.9
    }
  },
  methods: {
    adjustFontSize
  }
}
</script>

<style>
  .main-content.welcome-view .inner {
      display: flex;
      flex-direction: column;
      height: 93%;
  }
  .main-content.welcome-view .inner .head {
      margin-top: auto;
  }
  .main-content.welcome-view .inner .sub {
      height: auto;
  }
</style>
