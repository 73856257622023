<template>
  <div v-if="getCurrentViewType !== null" class="components-wrapper" :style="{borderTopColor: getCustomerColor}">
    <v-fade-transition mode="out-in">
        <Component :is="getCurrentViewTypeComponent" :customColor="getCustomerColor"/>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: "CreationHome",

  methods: {
    ...mapMutations({
      updateCreationView: 'updateCreationView'
    })
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCurrentViewTypeComponent: 'getCurrentViewTypeComponent',
      getCurrentViewType: 'getCurrentViewType',
      getCustomerColor: 'getCustomerColor'
    }),
  },

  mounted () {
    window.addEventListener('message', e => {
      const params = {
        item: e.data.item
      }
      this.updateCreationView(params)
    })
  }
}
</script>

<style scoped>

</style>
