function adjustFontSize(containerId, textId, defaultFontSize, minFontSize, substractId = null, overwriteContainerFontSize = false) {
    let container = document.getElementById(containerId)
    let text = document.getElementById(textId)
    text.style.fontSize = defaultFontSize + 'em'
    let containerHeight = container.offsetHeight
    if (substractId) {
        let substract = document.getElementById(substractId)
        if(substract) {
            let additionalSubstract = 0
            let textStyle = getComputedStyle(text)
            if (textStyle.marginBottom) {
                additionalSubstract += parseInt(textStyle.marginBottom)
            }
            containerHeight -= (substract.offsetHeight + additionalSubstract)
        }
    }

    let textHeight = text.offsetHeight

    let fontSize = parseInt(window.getComputedStyle(text).fontSize)

    if (textHeight > containerHeight) {
        while (textHeight > containerHeight && fontSize > 0) {
            fontSize -= 0.1
            if (fontSize < minFontSize) {
                text.style.overflow = 'hidden';
                fontSize += 0.1
                break;
            } else {
                text.style.fontSize = fontSize + 'em'
                textHeight = text.offsetHeight
            }
        }
    }
    if(overwriteContainerFontSize) {
        container.style.fontSize = fontSize + 'em'
    }
}

export {
    adjustFontSize
}
