export const projectapi = {
    // Live Server
    url: 'https://wesual-api.wesual.de/',
    // Test Server
    // Development Local
    // Development Local
    // url: 'http://127.0.0.1:8000/',
    path: {
        default: 'api',
        storage: 'storage/',
        upload: 'files/file-upload'
    }
}
