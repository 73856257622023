import axios from "axios";

const actions = {
    login({commit}, data) {
        return new Promise(resolve => {
            axios.post('view-auth/login', data).then((res) => {
                const params = {
                    token: res.data.token,
                    deviceId: data.activationId,
                    initialView: res.data.initialView,
                    color: res.data.color,
                    customer_id: res.data.customer_id,
                    logo_url: res.data.logo_url
                }
                commit('setCurrentLogo', res.data.logo_url)
                commit('login', params)
                resolve('home')
            })
        })
    },

    previewLogin({commit}, data) {
        return new Promise(resolve => {
            const params = {
                token: data.token,
                item: data.item
            }
            commit('previewLogin', params)
            resolve('creation')
        })
    },

    logout({commit}) {
        return new Promise(resolve => {
            axios.get('view-auth/logout').then(() => {
                commit('logout')
                resolve('login')
            })
        })
    },
    timeoutLogout({commit}) {
        return new Promise(resolve => {
            axios.post('views/forcedLogout')
            setTimeout(() => {
                commit('logout')
                resolve('login')
            }, 1000)
        })
    },
    fetchScreenUpdate({commit, getters}) {
        let params = {
            currentView: getters.getCurrentViewId,
            updateAtTimestamp: getters.getCurrentViewTypeTimestamp,
            logoTimestamp: getters.getCurrentLogoTimestamp
        }

        axios.post('views/views', params).then((res) => {
            if(res !== undefined) {
                if(res.data.view !== false) { // ToDo: what should happen if no standrad view is defined and no other view is returned? (data.view === false)
                    commit('setView', res.data)
                }
                if(res.data.logo !== false) {
                    commit('setCurrentLogo', res.data.logo)
                }
            }
        })
        if(!getters.getReloadWebview || getters.getReloadWebview === 'false') {
            if (new Date().getHours() === 4) {
                commit('setReloadWebview', true)
                window.location.reload()
                /*
                if(window.nsWebViewBridge !== undefined) {
                    window.nsWebViewBridge.emit('enforceReload')
                }
                 */
            }
        }
        // reset counter after 3pm
        // if reset state is true
        if(getters.getReloadWebview === true || getters.getReloadWebview === 'true') {
            if (new Date().getHours() > 4) {
                commit('setReloadWebview', false)
            }
        }

    }
}

export default actions
