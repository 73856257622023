<template>
  <div class="link-grid">
    <iframe :src="adjustedURL" frameBorder="0"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Link",
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
    }),
    adjustedURL() {
      let http = this.getCurrentViewContent.url.substring(0, 4)
      if(http === 'http') {
        return this.getCurrentViewContent.url
      } else {
        return 'http://'+this.getCurrentViewContent.url
      }
    }
  }
}
</script>

<style scoped>

</style>
