<template>
<div class="bulletin-board_wrapper">
<!--  <div>-->
<!--    <div class="header-bar">-->
<!--      <div class="icon"></div>-->
<!--      <div class="logo"></div>-->
<!--    </div>-->
<!--  </div>-->
  <div data-app class="bulletin-main">
    <div class="padding-frame">
      <div class="column-wrapper flexbox">
        <div class="column-1">
<!--          {{ getCurrentViewContent }}-->
<!--          {{ getCurrentViewContent.weather_label1 }}-->
<!--          {{ getCurrentViewContent.weather_label2 }}-->
<!--          {{ getCurrentViewContent.weather_location }}-->
<!--          {{ getCurrentViewContent.date_title }}-->
<!--          {{ getCurrentViewContent.date_text }}-->
<!--          {{ getCurrentViewContent.date_datetime }}-->
          <div class="weather-information">
            <h2>Wetterinformationen</h2>
            <div class="frame-wrapper flexbox">
              <!--              <iframe src="https://www.meteoblue.com/de/wetter/widget/three/bad-neustadt-an-der-saale_deutschland_2953389?geoloc=fixed&nocurrent=0&noforecast=0&days=5&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&layout=image"  frameborder="0" scrolling="NO" allowtransparency="true" sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"-->
              <!--                      style="height: 596px; width: 600px; max-height: 596px; max-width: 600px;"></iframe>-->
              <!-- Widget von https://weatherwidget.org/ -->
              <!--              <div id="ww_02de690817ff0" v='1.3' loc='id' a='{"t":"responsive","lang":"de","sl_lpl":1,"ids":["wl3440"],"font":"Arial","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_tof":"3"}'>Mehr Vorhersagen: <a href="https://oneweather.org/de/deutschland/21_tage/" id="ww_02de690817ff0_u" target="_blank">21 tage wettervorhersage</a></div><script async src="https://app3.weatherwidget.org/js/?id=ww_02de690817ff0"></script>-->
              <!-- Widget von https://weatherwidget.io/ -->
              <!--              <a class="weatherwidget-io" href="https://forecast7.com/de/50d5710d42/meiningen/" data-label_1="Meiningen" data-label_2="Wetter" data-icons="Climacons Animated" data-days="3" data-theme="weather_one" >Meiningen Wetter</a>-->
              <a class="weatherwidget-io" :href="'https://forecast7.com/de/'+`${getCurrentViewContent.weather_location}`" :data-label_1="getCurrentViewContent.weather_label1" :data-label_2="getCurrentViewContent.weather_label2" data-icons="Climacons Animated" data-days="3" data-theme="weather_one" >Meiningen Wetter</a>
              <!-- the following can not be used directly in template tag -->
              <!--              <script>-->
              <!--                !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');-->
              <!--              </script>-->
            </div>
          </div>
          <div class="date-information">
            <h2>Termininformationen</h2>
            <template v-if="getCurrentViewContent.date_title">
              <div class="date-title">{{ getCurrentViewContent.date_title }}</div>
<!--              <div class="date-date">{{ getCurrentViewContent.date_datetime }}</div>-->
<!--              <div class="date-date">{{ new Date(getCurrentViewContent.date_datetime).toLocaleDateString("de-DE", {day: "2-digit", month: "long", year: "numeric"}) }}</div>-->
              <div class="date-date">{{ new Date(getCurrentViewContent.date_datetime).toLocaleDateString("de-DE", {day: "2-digit", month: "numeric", year: "numeric"}) }}</div>
              <div class="date-text" v-html="getCurrentViewContent.date_text"/>
            </template>
            <template v-else>
              <div class="date-text">Zur Zeit stehen keine Termine an</div>
            </template>
          </div>
          <div class="ap-wrapper" v-if="getCurrentViewContent.ap1_name">
            <h2>Ansprechpartner</h2>
            <template v-if="getCurrentViewContent.ap1_name">
              <p><span class="ap-name">{{ getCurrentViewContent.ap1_name }}</span><span v-if="getCurrentViewContent.ap1_position" class="ap-position"> / {{ getCurrentViewContent.ap1_position }}</span><span v-if="getCurrentViewContent.ap1_contact" class="ap-contact"> - {{ getCurrentViewContent.ap1_contact }}</span></p>
            </template>
            <template v-if="getCurrentViewContent.ap2_name">
              <p><span class="ap-name">{{ getCurrentViewContent.ap2_name }}</span><span v-if="getCurrentViewContent.ap2_position" class="ap-position"> / {{ getCurrentViewContent.ap2_position }}</span><span v-if="getCurrentViewContent.ap2_contact" class="ap-contact"> - {{ getCurrentViewContent.ap2_contact }}</span></p>
            </template>
            <template v-if="getCurrentViewContent.ap3_name">
              <p><span class="ap-name">{{ getCurrentViewContent.ap3_name }}</span><span v-if="getCurrentViewContent.ap3_position" class="ap-position"> / {{ getCurrentViewContent.ap3_position }}</span><span v-if="getCurrentViewContent.ap3_contact" class="ap-contact"> - {{ getCurrentViewContent.ap3_contact }}</span></p>
            </template>
          </div>
<!--          <h2>Neuigkeiten rundum FGB</h2>-->
<!--&lt;!&ndash;          <div style="width: 100%; margin-bottom: 20px; max-height: 30%;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url">&ndash;&gt;-->
<!--&lt;!&ndash;            <v-img :src="getCurrentViewFiles[0].url" contain max-height="100%" style="width: 100%;"/>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <v-img :src="getCurrentViewFiles[0].url" style="width: 100%;"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <v-img :src="getCurrentViewFiles[0].url" max-height="30%" style="width: 100%; margin-bottom:20px;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url" />&ndash;&gt;-->
<!--          <v-img :src="getCurrentViewFiles[0].url" contain max-height="30%" style="width: 100%; margin-bottom:20px;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url" />-->
<!--          <p class="date"><strong>{{ new Date(getCurrentViewContent.date_news).toLocaleDateString("de-DE", {day: "2-digit", month: "long", year: "numeric"}) }}</strong></p>-->

<!--          <h4>{{ getCurrentViewContent.headline_news }}</h4>-->

<!--          <div class="text_news" v-html="getCurrentViewContent.text_news">-->
<!--          </div>-->
        </div>
        <div class="column-2">
          <div class="vr-logos">
            <div class="vr-logo immo-frank-logo"></div>
            <div class="vr-logo immo-service-logo"></div>
            <div class="vr-logo vr-bank-main-rhoen-logo"></div>
          </div>
          <div class="vr-gallery-wrapper">
            <div id="image-container">

            </div>
          </div>
          <div class="emergency-teaser-wrapper">
            <h2>Not- und Havarieplan</h2>
            <div class="emergency-teaser" v-html="getCurrentViewContent.emergency_teaser"/>
            <div class="emergency-contact">
              <div class="emergency-contact-left" v-html="getCurrentViewContent.emergency_contact_left">
              </div>
              <div class="emergency-contact-right" v-html="getCurrentViewContent.emergency_contact_right">
              </div>
              <div class="emergency-lightbox-btn">
                <v-btn
                    x-large
                    color="#0E2C65"
                    dark
                    @click="openEmergencyLightbox">
                  <v-icon left>
                    mdi-arrow-right
                  </v-icon>Zur Übersicht
                </v-btn>
              </div>
            </div>
          </div>
          <div class="emergency-lightbox-wrapper" ref="emergency-lightbox">
            <!-- :attach="$refs['emergency-lightbox']" -->
            <!-- following not needed (would also not fully work) if setting data-app for <div data-app class="bulletin-main"> -->
            <!-- :attach="'.bulletin-main'"
                :hide-overlay="false"
                :dark="true"
                :persistent="false"
                -->
            <v-dialog
                v-model="ermergencyLightbox"
                content-class="emergency-lightbox-dialog"
            >
              <div class="emergency-lightbox-content">
                <v-btn
                    rounded
                    depressed
                    color="white"
                    class="lightbox-closing-btn"
                    @click="closeEmergencyLightbox"
                >
                  <v-icon left>
                    mdi-close
                  </v-icon>SCHLIESSEN
                </v-btn>
                <h2>Not- und Havarieplan</h2>
                <h3>Ansprechpartner</h3>
<!--                {{ getCurrentViewContent.emergency_lightbox_content }}-->
                <div class="emergency-lightbox-html-content" v-html="getCurrentViewContent.emergency_lightbox_content"></div>
                <div style="clear:both;"></div>
              </div>
            </v-dialog>
          </div>
        </div>
<!--        <div class="column-3">-->
<!--          <div class="newest-member">-->
<!--            <h2>Herzlich Willkommen</h2>-->
<!--            <div class="flexbox" style="height: 237px">-->
<!--              <div class="icon"></div>-->
<!--              <div class="text" v-html="getCurrentViewContent.date_info_text">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="gallery">-->
<!--            <h2>Fotogalerie</h2>-->
<!--            <div class="frame-wrapper flexbox">-->
<!--              <iframe src="https://www.fgb.de/viewneo/bb_gallery/bb_gallery.php" style="height: 560px; width: 685px; max-height: 560px; max-width: 685px;"></iframe>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>
  </div>
<!--  <div class="newsticker">-->
<!--    <iframe src="https://www.fgb.de/viewneo/lauftext/spiegel_feed.php" frameborder="0"></iframe>-->
<!--  </div>-->
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VrEmergencyBulletinBoard",
  data () {
    return {
      ermergencyLightbox: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
        getCurrentViewFiles: 'getCurrentViewFiles'
    })
  },
  methods: {
    // Funktion zum Anzeigen der Bilder
    displayImages(startIndex) {
      const container = document.getElementById('image-container');
      container.innerHTML = '';

      if (this.getCurrentViewFiles && this.getCurrentViewFiles.length > 0) {
        for (let i = 0; i < 3; i++) {
          if (startIndex + i < this.getCurrentViewFiles.length) {
            const img = document.createElement('img');
            img.src = this.getCurrentViewFiles[startIndex + i].url;
            img.alt = `Image ${startIndex + i + 1}`;
            img.className = 'gallery-image'
            // img.style.width = '30%'; // Anpassen der Bildgröße
            img.style.margin = '0 1.5%'; // Abstand zwischen den Bildern
            container.appendChild(img);
          }
        }
      }

    },
    // Hauptfunktion für Galerie-Roatation
    rotateImages() {
      let currentIndex = 0;

      // Initial display
      this.displayImages(currentIndex);

      // Wenn mehr als 3 Bilder vorhanden sind, starten wir die Rotation
      if (this.getCurrentViewFiles.length > 3) {
        setInterval(() => {
          currentIndex = (currentIndex + 3) % this.getCurrentViewFiles.length;
          this.displayImages(currentIndex);
        }, 10000); // 10 Sekunden Intervall
      }
    },
    openEmergencyLightbox () {
      this.ermergencyLightbox = true
    },
    closeEmergencyLightbox () {
      this.ermergencyLightbox = false
    }
  },
  mounted () {
    // append script of weatherwidget.io (if not already loaded -> see https://stackoverflow.com/a/67184038)
    // console.log('perfomrance', performance.getEntries())
    let resources = performance.getEntries()
        .filter(e => e.entryType === 'resource')
        .map(e => e.name);
    // console.log('resources in mounted after filtering', resources)
    if (resources.indexOf("https://weatherwidget.io/js/widget.min.js") === -1) {
      // Script was not yet loaded.
      var weatherWidgetScript = document.createElement('script');
      weatherWidgetScript.text = "!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');"
      document.head.appendChild(weatherWidgetScript);
    } else {
      // to retrigger render of weatherwidget.io widget
      if (typeof window.__weatherwidget_init === 'function') {
        window.__weatherwidget_init()
      }
    }
    // start gallery
    this.rotateImages()
  }
}
</script>

<style scoped>
.bulletin-board_wrapper{
  margin: 0;
  /*color: #fff;*/
  color: #0E2C64;
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'Roboto', sans-serif;
  width: 1200px;
  max-width: 1200px;
  height: 800px;
  max-height: 800px;
  /*border:1px solid black;*/ /* DEV */
  overflow:hidden;
}
h2{
  font-size: 30px;
  font-family: 'Roboto-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color:#0E2C64;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom:13px;
}
h4{
  margin: 0 0 40px 0;
}
ul{
  padding: 0;
}
li{
  list-style-type: none;
  padding: 0 0 20px 0;
}
strong{
  font-family: 'Roboto-bold', sans-serif;
}

.flexbox{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}
.padding-frame{
  /*padding: 0 30px;*/
  /* um Bereiche mit farbigen Hintergründen abzutrennen, soll jeder Breich selbst ein Padding einführen */
  padding: 0;
}
.header-bar .icon{
  background: url("./group-logo.jpeg") no-repeat;
  height: 50px;
  width: 50px;
  background-size: cover;
  float: left;
}

.header-bar{
  height: 100px;
  align-items: center;
  justify-content: flex-end;
}
.header-bar > .logo{
  float: right;
  line-height: 100px;
  background: url("./logo.png");
  height: 50px;
  width: 110px;
  margin: 25px 40px 25px 0;
}

.column-wrapper{
  width: 100%;
}
.column-wrapper > div{
  overflow: hidden;
}
.column-1{
  min-width: 485px;
  max-width: 570px;
  /*width: calc(30% - 40px);*/
  /*width: calc(50% - 40px);
  margin: 20px 40px 10px 0;*/
  /* um Bereiche mit farbigen Hintergründen abzutrennen, soll jeder Breich selbst ein Padding einführen */
  width:50%;
  max-width:600px;
  max-height: 800px;
}
.column-1 > div {
  padding: 0 30px;
}
.column-1 .date-information {
  min-height: 335px;
  height: 335px;
  max-height: 335px;
  overflow: hidden;
  margin-bottom: 15px;
}
.column-1 .date-information .date-title {
  text-transform: uppercase;
  font-size:22px;
  font-weight:bold;
}
.column-1 .date-information .date-date {
  text-transform: uppercase;
  font-size:22px;
  font-weight:bold;
  margin-bottom:10px;
}
.column-1 .date-information .date-text {
  font-size:18px;
  margin-bottom:15px;
}
.column-1 .ap-wrapper {
  background-color:#F3F7FB;
  padding-top: 1px;
  padding-bottom: 600px; /* um den Hintergrund bis zum Ende anzuzeigen */
  font-size:21px;
}
.column-1 .ap-wrapper p {
  margin-bottom:7px;
}
.column-1 .ap-wrapper .ap-name {
  font-weight:bold;
}
.column-1 .ap-wrapper .ap-position {
  color:#8290A5;
}
.column-2{
  /*width: calc(33% - 40px);*/
  width: calc(50%);
  /*margin: 20px 0 10px 0;*/
  min-width: 500px;
  max-width: 600px;
  max-height: 800px;
}
.column-2 > div {
  padding: 0 30px;
}
.column-3{
  width: 37%;
  margin: 20px 0 10px 0;
  min-width: 675px;
  max-width: 675px;
  max-height: 890px;
}
.column-2 .vr-logos {
  height:85px;
  display: flex;
  justify-content: space-between;
  margin-bottom:25px;
}
.column-2 .vr-logos .vr-logo {
  display:inline-block;
  height:100%;
  width:32%;
}
.column-2 .vr-logos .immo-frank-logo {
  background-image: url("./emp_indisigno_vr-bank_logo_immobilien-frank.jpg");
  background-repeat: no-repeat;
  /*background-size: 100% 100%;*/
  background-size:contain;
  background-position:center;
}
.column-2 .vr-logos .immo-service-logo {
  background-image: url("./emp_indisigno_vr-bank_logo_vr-immobilien.jpg");
  background-repeat: no-repeat;
  /*background-size: 100% 100%;*/
  background-size:contain;
  background-position:center;
}
.column-2 .vr-logos .vr-bank-main-rhoen-logo {
  background-image: url("./emp_indisigno_vr-bank_logo_vr-brank.jpg");
  background-repeat: no-repeat;
  /*background-size: 100% 100%;*/
  background-size:contain;
  background-position:center;
}
.column-2 .vr-gallery-wrapper {
  /*background-color:#FAFAFA;*/
  background: linear-gradient(#fff 60%, #FAFAFA 60%);
  padding-bottom: 35px;
}
.vr-gallery-wrapper #image-container {
  display:flex;
  justify-content: space-around;
  align-items:center;
  max-height:180px;
}
.vr-gallery-wrapper #image-container ::v-deep img {
  width:auto;
  max-width:30%;
  height:auto;
  max-height:180px;
}
.column-2 .emergency-teaser-wrapper {
  background-color:#EEF2F6;
  padding-top:1px;
  padding-bottom:700px;
}
.column-2 .emergency-teaser-wrapper .emergency-teaser {
  font-size:18px;
  padding-bottom: 15px;
  border-bottom:2px solid white;
}
.column-2 .emergency-teaser-wrapper .emergency-contact {
  display:flex;
  justify-content: space-between;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom:2px solid white;
}
.column-2 .emergency-teaser-wrapper .emergency-contact > div {
  width:50%;
}
/*.column-2 .emergency-teaser-wrapper .emergency-contact .emergency-contact-left ::v-deep p {*/
.column-2 .emergency-teaser-wrapper .emergency-contact > div ::v-deep p {
  margin-bottom:10px;
}
.column-2 .emergency-teaser-wrapper .emergency-contact .emergency-lightbox-btn {
  position: absolute;
  right: 30px;
  bottom: 41px;
  width: auto;
}
.column-2 .emergency-teaser-wrapper .emergency-contact .emergency-lightbox-btn .v-btn {
  border-radius:13px;
}
.column-2 .date-information{
  max-height: 237px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 3px solid #EEE;
  display: block;
}
.column-2 .date-information div {
  max-height: 170px;
  min-height: 170px;
  overflow: hidden;
}
.column-2 .date-information ul li{
  overflow: hidden;
  -webkit-box-orient: vertical;
  height: 20px;
  padding: 0;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}
.column-2 .weather-information{
  height: 65%;
  width: 100%;
}
.column-2 .weather-information iframe{
  margin-top: 10px;
  width: 100%;
  border: none;
  height: 542px;
}
.column-3 .newest-member{
  max-height: 237px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 3px solid #EEE;
}
.column-3 .newest-member .icon{
  background: url("./icon-m.png") no-repeat center 4px;
  max-height: 160px;
  width: 80px;
  min-width: 80px;
  float: left;
  margin-right: 25px;
}
.column-3 .newest-member .text{
  float: right;
  max-height: 165px;
  overflow: hidden;
}

.column-3 .gallery iframe{
  border: none;
  height: 560px;
  min-width: 685px;
  max-width: 685px;
}
.gallery h2{
  margin-bottom: 0;
}
.weatherwidget-io {
  width:100%;
}

.frame-wrapper{
  justify-content: center;
}

.newsticker{
  background-color: #4995D1;
  height: 60px;
}
.newsticker iframe{
  background-color: #4995D1;
  opacity: 1;
  width: 100%;
  height: 60px;
}

.padding-frame{
  /*background-image: url("./background.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;*/
}
</style>
<style>
  .bulletin-board_wrapper .emergency-lightbox-dialog {
    border-radius:16px;
  }
  .emergency-lightbox-content {
    padding: 30px;
    background-color: white;
    position:relative;
  }
  .emergency-lightbox-content .lightbox-closing-btn {
    background-color:transparent!important;
    box-shadow:none!important;
    position:absolute;
    top:12px;
    right:12px;
  }
  .emergency-lightbox-content h3 {
    font-size:22px;
    text-transform: uppercase;
    margin-bottom:15px;
  }
  .emergency-lightbox-content a {
    text-decoration: none;
    color:#0E2C64;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content {
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    max-height:580px;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content p, .emergency-lightbox-content .emergency-lightbox-html-content ul {
    max-width:47%;
    width:47%;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content p {
    padding-top:15px;
    border-top:2px solid #EFF2F4;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content ul {
    list-style: none;
    display:flex;
    justify-content: space-between;
    padding-bottom:15px;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content ul li:nth-child(1){
    width:60%;
  }
  .emergency-lightbox-content .emergency-lightbox-html-content ul li:nth-child(2) {
    width:40%;
  }
</style>
