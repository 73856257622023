<template>
  <div class="wrapper">
    <div v-if="getCurrentViewType !== null && getCurrentViewType !== 7" class="components-wrapper" :key="getCurrentViewType">
      <div style="position: absolute; height: 10px; width: 100%; border-top: 10px;" :style="{borderTopColor: getCustomerColor}"></div>
      <v-fade-transition mode="out-in">
        <div v-show="getRenderedScreenAnimation" class="comp-wrapper">
          <Component :is="getCurrentViewTypeComponent"
                     :customColor="getCustomerColor"
                     :orientation="orientationMode"
                     :key="getLastViewUpdate"
          />
        </div>
      </v-fade-transition>
      <v-overlay :value="overlay" :opacity="opacity" class="overlay">
        <div>
          Es konnte keine Internetverbindung gefunden werden.
        </div>
      </v-overlay>
    </div>
    <div v-if="getCurrentViewType !== null && getCurrentViewType === 7" class="components-wrapper">
      <div v-show="getRenderedScreenAnimation" class="comp-wrapper"> <!-- ToDo @Markus: after reload SimpleLogo.vue is not shown -->
        <Component :is="getCurrentViewTypeComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      screenFetcher: null,
      orientationMode: null,
      onlineState: true,
      opacity: 0.9
    }
  },
  watch: {
    getOfflineCounter: {
      handler(state) {
        if(state > 180) {
          /*
          this.timeoutLogout().then(redirect => {
            this.$router.push({name: redirect})
          })
           */
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchScreenUpdate: 'fetchScreenUpdate',
      timeoutLogout: 'timeoutLogout'
    }),
    ...mapMutations({
      setOfflineCounter: 'setOfflineCounter'
    }),
    handleOrientationChange() {
      let orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        this.orientationMode = 1
      } else if (orientation === "landscape-primary") {
        this.orientationMode = 2
      }
    }
  },
  computed: {
    ...mapGetters({
      getRenderedScreen: 'getRenderedScreen',
      getRenderedScreenAnimation: 'getRenderedScreenAnimation',
      getCurrentViewContent: 'getCurrentViewContent',
      getCurrentViewTypeTimestamp: 'getCurrentViewTypeTimestamp',
      getCurrentViewTypeComponent: 'getCurrentViewTypeComponent',
      getLastViewUpdate: 'getLastViewUpdate',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCurrentViewType: 'getCurrentViewType',
      getCustomerColor: 'getCustomerColor',
      getOfflineCounter: 'getOfflineCounter'
    }),
    ...mapState({
      currentViewType: 'currentViewType'
    }),
    overlay() {
      if(this.getOfflineCounter > 30) {
        return true
      } else {
        return false
      }
    }
  },

  created() {
    this.screenFetcher = setInterval(() => {
      this.fetchScreenUpdate()
    }, 60000) // live
    // }, 6000) // DEV
    this.onlineState = window.navigator.onLine

    window.addEventListener("offline", () => {
      this.onlineState = false
    })
    window.addEventListener("online", () => {
      this.onlineState = true
    })
  },
  mounted() {
    window.addEventListener(
        'orientationchange',
    this.handleOrientationChange)
      this.fetchScreenUpdate()
  },
  beforeRouteUpdate(to, from, next) {
    clearInterval(this.screenFetcher)
    next()
  },
  beforeDestroy() {
    clearInterval(this.screenFetcher)
  }
}
</script>
<style>
.wrapper {
  height: 100vh;
  width: 100vw;
}
</style>
