<template>
  <div id="app">
    <router-view/>
    <vue-headful :title="projectSettings.title"/>
  </div>
</template>

<script>
const projectSettings = require('@projectroute/config/main')
export default {
  data() {
    return {
      projectSettings: projectSettings
    }
  }
}
</script>
<style>
  @import "./assets/digital_signage.scss";
</style>
components-wrapper
