<template>
  <div class="view-frame news">
    <div class="color-bar" :style="{backgroundColor: getCustomerColor}"></div>
    <div class="spacing-frame">
      <div class="logo-frame">
        <img :src="computedImageSrc" @error="computedImageSrc = null"/>
      </div>
      <div class="main-content news-view">
        <div class="news-info-wrapper" id="inner">
          <div class="news-header" ref="news-header">
            <span id="head" class="news-header-text">{{ getCurrentViewContent.header }}</span>
          </div>
          <div class="divider"/>
          <div class="news-date" id="sub">
            <span>
                {{ new Date(getCurrentViewContent.date).toLocaleDateString("de-DE", {day: "2-digit", month: "long", year: "numeric"}) }}
            </span>
          </div>
        </div>
        <div class="news-text">
          <div class="news-text-wrapper" ref="news-text">
            <div
                class="news-text-content"
                ref="news-text-content"
                :style="{paddingBottom: calculatedTotalPadding+'px'}"
            >
            <span ref="news-text-span"
                  v-html="getCurrentViewContent.text">
            </span>

            </div>
          </div>

          <div class="news-page-indicator">
            <span v-if="totalPages > 1">
              Seite {{ currentPage }} von {{ totalPages }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery">
      <NewsGallery/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import NewsGallery from "@/views/news/NewsGallery";
import {adjustFontSize} from "@/utils/helpers"
export default {
  components: {NewsGallery},
  props: ['customColor', 'orientation'],
  name: "News",
  mounted() {
    this.$nextTick(() => {
      this.autoScroll()
      this.adjustFontSize('inner', 'head', this.defaultFontHeight, window.innerHeight > window.innerWidth ? this.minPortraitFontHeight : this.minFontHeight, 'sub')
    })
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId',
      getCustomerColor: 'getCustomerColor',
      getResetNewsScroll: 'getResetNewsScroll',
      getLogoUrl: 'getLogoUrl'
    }),
    computedImageSrc () {
      return this.getLogoUrl || this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
    },
    calculatedTotalPadding() {
      return (this.totalPages * this.pageHeight) - this.totalHeight
    },
  },
  beforeRouteLeave(to, from, next) {
    if(this.slideIntervalId === null) {
      clearInterval(this.slideIntervalId)
    }
    next()
  },
  watch: {
    getCurrentViewContent: {
      handler() {
        clearInterval(this.slideIntervalId)
        this.autoScroll()
        this.$nextTick(() => {
            this.adjustFontSize('inner', 'head', this.defaultFontHeight, window.innerHeight > window.innerWidth ? this.minPortraitFontHeight : this.minFontHeight, 'sub', true)
        })
      }
    },
    orientation: {
      handler(state) {
        if(state !== null) {
          clearInterval(this.slideIntervalId)
          this.autoScroll()
        }
      }
    },
    getResetNewsScroll: {
      handler(state) {
        if(state) {
          location.reload()
          clearInterval(this.slideIntervalId)
          this.autoScroll()
          this.setResetNewsScroll(false)
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.slideIntervalId)
  },
  methods: {
    adjustFontSize,
    ...mapMutations({
      setResetNewsScroll: 'setResetNewsScroll'
    }),
    autoScroll() {
      this.$refs['news-text'].style.height = null
      let newsTextWrapper = this.$refs['news-text'].getBoundingClientRect().height
      let newsTextContent = this.$refs['news-text-content'].getBoundingClientRect().height
      let pages = newsTextContent / newsTextWrapper
      let lh = window.getComputedStyle(this.$refs['news-text-content']).lineHeight
      let lhI = parseInt(lh.substring(0,lh.length-2))
      this.totalHeight = newsTextContent

      //check for height
      let linesInSpace = Math.floor(newsTextWrapper / lhI)
      this.pageHeight = lhI * linesInSpace
      this.totalPages = Math.ceil(pages)
      this.visibleLines = linesInSpace
      this.$refs['news-text'].setAttribute('style', 'height:'+(linesInSpace * lhI)+'px;')

      this.slideIntervalId = setInterval(() => {
        if(this.currentPage < this.totalPages) {
          this.$refs['news-text'].scrollTo({
            top: this.pageHeight * this.currentPage,
            behavior: 'smooth'
          })
          this.currentPage += 1
        } else {
          this.$refs['news-text'].scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          this.currentPage = 1
        }
      }, 20000)
    }
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      totalHeight: 0,
      pageHeight: 0,
      visibleLines: 0,

      calculatedPageHeight: 0,
      slideIntervalId: null,
      imageSrc: this.getResourcePath+this.getCustomerId+'/logo/logo.svg',
      minFontHeight: 1.2,
      minPortraitFontHeight: 2.4,
      defaultFontHeight: 2.4,
      fontSize: 2.4
    }
  }
}
</script>

<style>
  @media (orientation: landscape) .main-content.news-view .news-info-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
  }
  .main-content.news-view .news-info-wrapper .news-header {
      margin-top: auto;
  }
  .main-content.news-view .news-header #head{
    margin-top: auto;
  }
  .main-content.news-view .inner .sub {
    height: auto;
  }
</style>
