<template>
  <div class="view-frame">
    <div class="color-bar" :style="{backgroundColor: getCustomerColor}"></div>
    <div class="spacing-frame">
      <div class="logo-frame">
        <img :src="computedImageSrc" @error="computedImageSrc = null"/>
      </div>
      <div class="main-content notice-view" :class="getArrowClass()" style="margin-top: 30px;">
        <div class="inner" id="inner" :style="{width: this.getCurrentViewContent.direction != 0 ? '80%' : ''}">
          <span class="head" id="head">{{ getCurrentViewContent.header }}</span>
          <span v-if="getCurrentViewContent.period_start" class="sub" id="sub">{{ getCurrentViewContent.period_start }} - {{ getCurrentViewContent.period_end }} Uhr</span>
        </div>
        <div class="arrow" v-if="this.getCurrentViewContent.direction != 0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.433 140.196">
            <path id="9841561" data-name="9841561" d="M1395.944,982.438l70.1,70.1-70.1,70.1-11.311-11.313,50.786-50.786h-111.81v-16h111.81l-50.786-50.786Z" transform="translate(-1323.608 -982.438)" fill="#c9c9c9"/>
          </svg>
        </div>
      </div>
      <div class="room-number">
        <div class="inner">
          {{ getCurrentViewContent.room_number }}
        </div>
      </div>
      <div class="sub-content">
        <div class="inner small">
          {{ getCurrentViewContent.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {adjustFontSize} from "@/utils/helpers"
export default {
  name: "Notice",
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCustomerColor: 'getCustomerColor',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId',
      getLogoUrl: 'getLogoUrl'
    }),
    computedImageSrc () {
      return this.getLogoUrl || this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
    },
  },
  data() {
    return {
      imageSrc: null,
      minFontHeight: 1.6,
      defaultFontHeight: 2.9,
      fontSize: 2.9,
      minPortraitSmallFontHeight: 2.0,
      defaultFontHeightPortraitSmall: 2.2,
     }
  },
  methods: {
    adjustFontSize,
    getArrowClass () {
      let classes = ''
      if (this.getCurrentViewContent.direction) {
        classes = classes + 'arrow'
      }
      if (this.getCurrentViewContent.direction === '1') {
        classes = classes + ' right'
      } else if (this.getCurrentViewContent.direction === '2') {
        classes = classes + ' down'
      } else if (this.getCurrentViewContent.direction === '3') {
        classes = classes + ' left'
      } else if (this.getCurrentViewContent.direction === '4') {
        classes = classes + ' up'
      } else {
        classes = classes + ' hidden'
      }
      return classes
    }

  },
  mounted () {
    this.$nextTick(() => {
        this.adjustFontSize('inner', 'head', (window.innerHeight > window.innerWidth && window.innerWidth <= 700) ? this.defaultFontHeightPortraitSmall : this.defaultFontHeight, this.minFontHeight, 'sub')
    })
  },
  watch: {
    getCurrentViewContent: {
      handler() {
          this.$nextTick(() => {
              this.adjustFontSize('inner', 'head', (window.innerHeight > window.innerWidth && window.innerWidth <= 700) ? this.defaultFontHeightPortraitSmall : this.defaultFontHeight, this.minFontHeight, 'sub')
          })
      },
      deep: true
    }
  }
}
</script>

<style>
  .main-content.notice-view .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .main-content.notice-view .inner .head {
      margin-top: auto;
  }
  .main-content.notice-view .inner .sub {
      height: auto;
  }
</style>
