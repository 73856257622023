import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login";
import Home from "@/views/Home";
import Creation from "@/views/CreationHome";
import Preview from "@/views/Preview.vue";

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login'},
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/creation',
    name: 'creation',
    component: Creation
  },
  {
    path: '/preview/:type/:id/:typeId/',
    name: 'preview',
    component: Preview
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next){
  if(to.name === 'home') {
    if(!localStorage.getItem('token')) {
      router.push({name: 'login'})
    } else {
      next()
    }
  } else if(to.name === 'creation') {
    if(!localStorage.getItem('token')) {
      router.push({name: 'login'})
    } else {
      next()
    }
  } else if(to.name === 'login') {
    if(localStorage.getItem('token')) {
      if(from.name !== 'home') {
        router.push({name: 'home'})
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
