export const projectapi = {
    // Live Server
    url: 'https://wesual-api.wesual.de/',
    // Test Server
    // Development Local
    // Development Local
    // url: 'http://192.168.1.113:8000/', // use sudo php artisan serve --host 192.168.1.113 --port 8000 on api
    // url: 'http://127.0.0.1:8000/',
    // url: 'http://192.168.178.150:80/',
    // url: 'http://digisign-api.local/',
    path: {
        default: 'api',
        storage: 'storage/',
        upload: 'files/file-upload'
    }
}
