<template>
  <div class="login-wrapper">
    <div class="top-bg">
    </div>

    <div class="logo">
      <img :src="require('../projects/' + project + '/static/img/logo/logo.svg')"/>
    </div>

    <div class="bottom-bg">
      <div class="version-info">
        {{ versionInformation }}
      </div>
      <div class="form-wrapper">
        <div class="form-root">
          <div class="form-frame">
            <template>
              <v-form v-on:submit.prevent>
                <v-text-field id="activation-code"
                              label="Gerätecode"
                              v-model="activation_id"/>

                <v-btn type="submit" @click="attemptLogin" id="login">
                  Login
                </v-btn>
              </v-form>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
const MainSettings = require('./../../main.config.js')
export default {
  name: "Login",

  data() {
    return {
      activation_id: '',
      project: MainSettings.activeProject
    }
  },
  computed: {
    ...mapState({
      versionNumber: 'versionNumber',
      appVersionNumber: 'appVersionNumber'
    }),
    versionInformation () {
      let vers = ''
      vers = vers + this.versionNumber
      if(this.appVersionNumber) {
        vers = vers + ' (' + this.appVersionNumber + ')'
      }
      return vers
    }
  },
  methods: {
    ...mapActions({
      login: 'login',
      previewLogin: 'previewLogin'
    }),

    attemptLogin() {
      /*
      let device_id = null
      if(window.nsWebViewBridge !== undefined) {
        device_id = document.querySelector('#activation-code').value
      } else {
        device_id = this.activation_id
      }
       */
      let device_id = null
      device_id = this.activation_id
      const params = {
        activationId: device_id
      }

      this.login(params).then((redirect) => {
        /*
        if(window.nsWebViewBridge !== undefined) {
          window.nsWebViewBridge.emit("loginSuccessful", { loginConfirmed: true });
        }
         */
        this.$router.push({name: redirect})
      })
    }
  },

  mounted() {
    this.activation_id = localStorage.getItem('deviceId')

    /*
    window.addEventListener('message', (e) => {
      if(e.data.login !== undefined) {
        const params = {
          activationId: e.data.login,
          loginType: e.data.loginType,
          item: e.data.item,
          token: e.data.token
        }
        this.previewLogin(params).then((redirect) => {
          this.$router.push({name: redirect})
        })
      }
    })
    */
  }
}
</script>

<style>

</style>
