<template>
    <div class="preview">
        <div class="wrapper" v-if="!loading && !notFound">
            <div v-if="getCurrentViewType !== null && getCurrentViewType !== 7" class="components-wrapper">
                <div style="position: absolute; height: 10px; width: 100%; border-top: 10px;" :style="{borderTopColor: getCustomerColor}"></div>
                  <div v-show="getRenderedScreenAnimation" class="comp-wrapper">
                      <Component :is="getCurrentViewTypeComponent"
                                 :customColor="getCustomerColor"
                      />
                  </div>
            </div>
            <div v-if="getCurrentViewType !== null && getCurrentViewType === 7" class="components-wrapper">
                <div v-show="getRenderedScreenAnimation" class="comp-wrapper">
                    <Component :is="getCurrentViewTypeComponent"
                    />
                </div>
            </div>
        </div>
        <div v-if="!loading && notFound" class="no-data-available">
            <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158">
                <defs>
                    <clipPath id="clip-Instagram-Story_1">
                        <rect width="158" height="158"/>
                    </clipPath>
                </defs>
                <g id="Instagram-Story_1" data-name="Instagram-Story – 1" clip-path="url(#clip-Instagram-Story_1)">
                    <rect width="158" height="158" fill="#fff"/>
                    <g id="Gruppe_1119" data-name="Gruppe 1119" transform="translate(-875.141 -389.141)">
                        <g id="Gruppe_1118" data-name="Gruppe 1118" transform="translate(830.437 315.468)">
                            <circle id="Ellipse_12" data-name="Ellipse 12" cx="75.074" cy="75.074" r="75.074" transform="translate(48.704 76.673)" fill="none" stroke="#d8d8d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.5"/>
                            <path id="Pfad_232" data-name="Pfad 232" d="M154.1,99.493,139.06,84.449l-33.983,33.985L71.093,84.449,56.049,99.493l33.983,33.985L56.049,167.463l15.044,15.043,33.983-33.985,33.983,33.985L154.1,167.463,120.12,133.478Z" transform="translate(18.702 19.8)" fill="none" stroke="#d8d8d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.5"/>
                        </g>
                    </g>
                </g>
            </svg><br><br>
            Das aufgerufene Element wurde nicht gefunden<br> oder ist zwischenzeitlich gelöscht worden.<br><br>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import axios from "axios";
export default {
    name: "Preview",
    data() {
        return {
            loading: true,
            notFound: false
        }
    },
    /*
    metaInfo() {
        console.log('called metaInfo')
        return {
            meta: [
                {
                    vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=10'
                }
            ]
        }
    },
     */
    mounted() {
        if(this.$route.params.type === 'view') {
            axios.get('preview/preview-data-view/'+this.$route.params.id+'/'+this.$route.params.typeId).then(r => {
                if(r.data.status === -2) {
                    this.notFound = true
                } else {
                    this.notFound = false
                }
                this.loading = false
                this.setPreviewData(r.data.data)
            })
        } else if(this.$route.params.type === 'device') {
            axios.get('preview/preview-data-device/'+this.$route.params.id+'/'+this.$route.params.typeId).then(r => {
                if(r.data.status === -2) {
                    this.notFound = true
                } else {
                    this.notFound = false
                }
                this.loading = false
                this.setPreviewData(r.data.data)
            })
        }
    },
    methods: {
        ...mapMutations({
            setPreviewData: 'setPreviewData'
        }),
    },
    computed: {
        ...mapGetters({
            getRenderedScreen: 'getRenderedScreen',
            getRenderedScreenAnimation: 'getRenderedScreenAnimation',
            getCurrentViewContent: 'getCurrentViewContent',
            getCurrentViewTypeTimestamp: 'getCurrentViewTypeTimestamp',
            getCurrentViewTypeComponent: 'getCurrentViewTypeComponent',
            getCustomerIdentifier: 'getCustomerIdentifier',
            getCurrentViewType: 'getCurrentViewType',
            getCustomerColor: 'getCustomerColor',
            getOfflineCounter: 'getOfflineCounter'
        }),
        ...mapState({
            currentViewType: 'currentViewType'
        })
    },
}
</script>

<style scoped>
    .preview .no-data-available {
        text-align:center;
        margin-top:50px;
        font-size:26px;
        color:#AAAAAA;
        padding-bottom:60px;
    }
</style>
