<template>
  <div class="view-frame">
    <div class="color-bar" :style="{backgroundColor: getCustomerColor}"></div>
    <div class="spacing-frame">
      <div class="logo-frame">
        <img :src="computedImageSrc" @error="computedImageSrc = null"/>
      </div>
      <div class="main-content room-view" style="margin-top: 35px;">
        <div class="inner" id="inner">
          <span class="head" id="head">{{ getCurrentViewContent.room_name }}</span>
        </div>
      </div>
      <div class="room-number">
        <div class="inner">
          {{ getCurrentViewContent.room_number }}
        </div>
      </div>
      <div class="sub-content">
        <div class="inner">
          {{ getCurrentViewContent.persons }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {adjustFontSize} from "@/utils/helpers"
export default {
  name: "Welcome",
  mounted () {
    this.$nextTick(() => {
        this.adjustFontSize('inner', 'head', this.defaultFontHeight, this.minFontHeight)
    })
  },
  watch: {
    getCurrentViewContent: {
      handler() {
          this.$nextTick(() => {
              this.adjustFontSize('inner', 'head', this.defaultFontHeight, this.minFontHeight)
          })
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCustomerId: 'getCustomerId',
      getResourcePath: 'getResourcePath',
      getCustomerColor: 'getCustomerColor',
      getLogoUrl: 'getLogoUrl'
    }),
    computedImageSrc () {
      return this.getLogoUrl || this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
    }
  },
  methods: {
      adjustFontSize,
  },
  data() {
    return {
      header: 'Herzlich Willkommen',
      imageSrc: null,
      minFontHeight: 2,
      defaultFontHeight: 2.9,
      fontSize: 2.9
    }
  }
}
</script>

<style>
.main-content.room-view .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}
@media (orientation: portrait) {
    padding-bottom: 6%;
    position: unset;
}
.main-content.room-view .inner .head {
    margin-top: auto;
}
.main-content.room-view .inner .sub {
    height: auto;
}
</style>
