<template>
  <div class="gallery-frame flexbox">
    <div class="gallery-wrapper flexbox">
      <div class="box1">
        <v-img :src="pic1Url">
        </v-img>
      </div>
      <div class="box2">
        <v-img :src="pic2Url">

        </v-img>
      </div>
      <div class="box3">
        <v-img :src="pic3Url">

        </v-img>
      </div>
      <div class="box4-5-wrapper">
        <div class="box4">
          <v-img :src="pic4Url">

          </v-img>
        </div>
        <div class="box5">
          <v-img :src="pic5Url">

          </v-img>
        </div>
      </div>
      <div class="box6">
        <v-img :src="pic6Url">

        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Gallery",
  computed: {
    ...mapGetters({
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId',
      getCurrentViewFiles: 'getCurrentViewFiles'
    }),
    pic1Url() {
      if (this.getCurrentViewFiles) {
        let image1 = this.getCurrentViewFiles.filter(function(image) { // if used field type 'singleimage' with column name file.1
          return image.space_field_id == 1
        })
        if (image1 && image1.length) {
          return image1[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic1.png'
    },
    pic2Url() {
      if (this.getCurrentViewFiles) {
        let image2 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 2
        })
        if (image2 && image2.length) {
          return image2[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic2.png'
    },
    pic3Url() {
      if (this.getCurrentViewFiles) {
        let image3 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 3
        })
        if (image3 && image3.length) {
          return image3[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic3.png'
    },
    pic4Url() {
      if (this.getCurrentViewFiles) {
        let image4 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 4
        })
        if (image4 && image4.length) {
          return image4[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic4.png'
    },
    pic5Url() {
      if (this.getCurrentViewFiles) {
        let image5 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 5
        })
        if (image5 && image5.length) {
          return image5[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic5.png'
    },
    pic6Url() {
      if (this.getCurrentViewFiles) {
        let image6 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 6
        })
        if (image6 && image6.length) {
          return image6[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/gallery/pic6.png'
    }
  }
}
</script>

<style>

</style>
