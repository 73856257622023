<template>
  <div class="simple-logo-view">
    <img
        :src="computedImageSrc" @error="computedImageSrc = null"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "SimpleLogo",
  data () {
    return {
      imageSrc: null
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      getCustomerIdentifier: 'getCustomerIdentifier',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId',
      getLogoUrl: 'getLogoUrl'
    }),
    computedImageSrc () {
      return this.getLogoUrl || this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
    },
    imgSrc: {
      get: function() {
        return this.getResourcePath+this.getCustomerId+'/logo/logo.svg'
      },
      set: function(newValue) {
        return newValue
      }

    }
  }
}
</script>

<style scoped>

</style>
