import {api} from "@/config/api";

const state = {
    token: localStorage.getItem('token'),

    currentViewId: null,
    currentViewType: 0,
    currentViewTypeTimestamp: null,
    currentViewContent: null,
    currentViewFiles: [],

    lastViewUpdate: 0,

    renderedScreenAnimation: false,

    color: localStorage.getItem('color'),
    customerId: localStorage.getItem('customer_id'),
    resourcePath: api.url+api.path.storage+'files/',
    permissions: localStorage.getItem('permissions'),

    // logoUrl: localStorage.getItem('logo_url') === 'null' ? null : localStorage.getItem('logo_url'), // local storage not reactive
    logoUrl: null,
    currentLogoTimestamp: null,

    offlineCounter: 0,
    reloadWebview: localStorage.getItem('reloadWebview') ? localStorage.getItem('reloadWebview') : false,
    resetNewsScroll: false,
    versionNumber: 'v1.0.2.7',
    appVersionNumber: localStorage.getItem('appVersion')
}

export default state
