<template>
  <div class="news-gallery-comp">
    <div>
      <v-img :src="pic1Url"/>
    </div>
    <div>
      <v-img :src="pic2Url"/>
    </div>
    <div>
      <v-img :src="pic3Url"/>
    </div>
    <!-- possible way to do it for field type 'multiimage' (sorting maybe not as expected - sorting would be a ToDo for file-upload) -->
<!--    <div v-for="(item, i) in getCurrentViewFiles" :key="i">
      <v-img :src="item.url"/>
    </div>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NewsGallery",
  computed: {
    ...mapGetters({
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId',
      getCurrentViewFiles: 'getCurrentViewFiles'
    }),
    pic1Url() {
      if (this.getCurrentViewFiles) {
        let image1 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 1
        })
        if (image1 && image1.length) {
          return image1[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/news/pic0.jpg'
    },
    pic2Url() {
      if (this.getCurrentViewFiles) {
        let image2 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 2
        })
        if (image2 && image2.length) {
          return image2[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/news/pic1.jpg'
    },
    pic3Url() {
      if (this.getCurrentViewFiles) {
        let image3 = this.getCurrentViewFiles.filter(function(image) {
          return image.space_field_id == 3
        })
        if (image3 && image3.length) {
          return image3[0].url
        }
      }
      // fallback value
      return this.getResourcePath+this.getCustomerId+'/images/news/pic2.jpg'
    }
  },
}
</script>

<style>
  .news-gallery-comp {
    width: 100vw;
    height: 100%;
    display: flex;
  }
  .news-gallery-comp div {
    display: flex;
    width:100%;
  }
  .news-gallery-comp div:first-child {
    width: calc(100vw / 4)
  }
  .news-gallery-comp div:last-child {
    width: calc(100vw / 4)
  }
</style>
